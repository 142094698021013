<template>
  <div class="classes_page">
    <div></div>
    <div class="block lga" @click="handerCategory"></div>
    <div class="block pgs" @click="handerPgs"></div>
    <div class="block mps" @click="handerMps"></div>
  </div>
</template>

<script>
export default {
  name: "courseClass",
  components: {},
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {
    handerCategory() {
      if (!this.$route.query.welfare) {
        this.$router.push({ path: "/category" });
      } else {
        this.$router.push({
          path: "/courseList",
          query: {
            welfare: "welfare",
            courseId: 1,
            isPremium: 1,
          },
        });
      }
    },
    handerPgs() {
      if (this.$route.query.welfare) {
        console.log(123);
        this.$router.push({
          path: "/courseList",
          query: {
            welfare: this.$route.query.welfare,
            courseId: 39,
            isPremium: 1,
          },
        });
      } else {
        this.$router.push({
          path: "/courseList",
          query: {
            courseId: 39,
            isPremium: 1,
          },
        });
      }
    },
    handerMps() {
      if (this.$route.query.welfare) {
        this.$router.push({
          path: "/courseList",
          query: {
            welfare: this.$route.query.welfare,
            courseId: 40,
            isPremium: 1,
          },
        });
      } else {
        this.$router.push({
          path: "/courseList",
          query: {
            courseId: 40,
            isPremium: 1,
          },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.classes_page {
  width: 80%;
  margin: 0 auto;
  @include flex(row, space-around, center);
  .block {
    width: 30%;
    height: 514px;
    box-sizing: border-box;
    cursor: pointer;
    margin: 50px 0;
  }
  .lga {
    @include bg("../../assets/img/lesson/LGA.png");
  }
  .lga:hover {
    @include bg("../../assets/img/lesson/LGA_hover.png");
  }
  .mps {
    @include bg("../../assets/img/lesson/MPS.png");
  }
  .mps:hover {
    @include bg("../../assets/img/lesson/MPS_hover.png");
  }
  .pgs {
    @include bg("../../assets/img/lesson/PGA.png");
  }
  .pgs:hover {
    @include bg("../../assets/img/lesson/PGA_hover.png");
  }
}
</style>